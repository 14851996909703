import React, { useState } from 'react';

import * as Styled from './styles';

interface MainNavItem {
  title: string;
  slug: string;
}

const mainNavItems: MainNavItem[] = [
  {
    title: 'Home',
    slug: '/'
  },
  {
    title: 'Blog',
    slug: '/blog/'
  },
  {
    title: 'Projects',
    slug: '/resume/'
  },
  {
    title: 'Contact Me',
    slug: '/contact/'
  }
];

interface Props {
  clickHandler: () => any;
  openParam: boolean;
}



const MainNav: React.FC<Props> = ( {clickHandler, openParam} ) => {
  const [open, setOpen] = useState(openParam);



  return (
    <>
      <Styled.MainNav open={open}>
        {mainNavItems.map((item, index) => (
          <Styled.MainNavItem
            key={`nav-item-${index}`}
            to={item.slug}
            activeClassName="active"
            
          >
            {item.title}
          </Styled.MainNavItem>
        ))}
      </Styled.MainNav>
      <Styled.ToogleMainNav open={open} onClick={ () => { 
          setOpen(!open);
          clickHandler();
        } }>
        <span />
        <span />
        <span />
      </Styled.ToogleMainNav>
    </>
  );
};

export default MainNav;
