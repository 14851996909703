import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Container } from 'components/ui/Container/styles';

// Source https://chaseohlson.com/
export const Header = styled.header(({ triggered, transparentHeader, open }) => { 

  return [
  
  !transparentHeader && `background-color: rgba(26, 32, 44, .9);
   border-bottom: 1px solid rgba(79, 209, 197, .1);
   backdrop-filter: blur(4px);
  `,

  transparentHeader && `background-color: rgba(26, 32, 44, .1);
  `,

  transparentHeader && open && `background-color: rgba(26, 32, 44, .9);
  `,


  tw`fixed -mb-px w-full z-10 hover:shadow-md`,
  triggered && tw``,
  transparentHeader && triggered && `transition: all 0.5s ease;
                border-bottom: 1px solid rgba(79, 209, 197, .1);
                background-color: rgba(26, 32, 44, .8);
                backdrop-filter: blur(4px);`,
]})


export const Wrapper = styled(Container)`
  ${tw`items-center`};
`;

