import styled from 'styled-components';
import tw from 'tailwind.macro';

export interface StyledProps {
  center?: boolean;
}

export const TitleSection = styled.div`
  ${tw`flex flex-col w-full`};
`;

export const Title = styled.h2<StyledProps>`
  ${tw`w-full mb-4 text-3xl font-bold text-left uppercase`};
  ${({ center }) => center && tw`text-center`};
`;

export const SubTitle = styled.h4<StyledProps>`
  ${tw`w-full text-xs text-left text-indigo-600`};
  ${({ center }) => center && tw`text-center`};
`;

export const Separator = styled.h2<StyledProps>`
  ${tw`relative w-2 h-8 mb-6 -mt-2`};
  ${({ center }) => center && tw`mx-auto`};

  &:before {
    content: '';
    ${tw`absolute left-0 w-px h-full bg-indigo-500`};
  }

  &:after {
    content: '';
    ${tw`absolute w-px h-6 ml-1 bg-teal-400`};
  }
`;
