import React, { useEffect, useState, Fragment } from 'react';


import MainNav from './MainNav';
import Logo from './Logo';

import styled from 'styled-components';
import * as Styled from './styles';
import { useScrollPosition, useScrollXPosition, useScrollYPosition } from 'react-use-scroll-position';


interface Props {
  siteTitle: string;
  location: any;
}



const Header: React.FC<Props> = ({ siteTitle, location}) => {
  // Source https://github.com/brohlson/chaseohlson/blob/7457857e3bbc33aafb00a24cc21e06f83c3fda65/src/components/Header.js#L197
  const [open, setOpen] = useState(false);

  const scrollY = typeof window !== 'undefined' ? useScrollYPosition() : 0,  scrolled = scrollY !== 0;

  const rootPath = `${__PATH_PREFIX__}/`;

  let transparentHeader = location?.pathname === rootPath;


  

  const clickHandler = () => {
    setOpen(!open);
    }


  useEffect(() => {
    setOpen(false);
  }, [location, scrollY]);

  return (
      <Styled.Header triggered={scrolled} open={open} transparentHeader={transparentHeader}>
        <Styled.Wrapper triggered={open}>
          <Logo />
          <MainNav clickHandler={clickHandler} />
        </Styled.Wrapper>
      </Styled.Header>
)};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
