import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Link } from 'gatsby';

export const Logo = styled(Link)`
  ${tw`flex items-center invisible mr-auto text-indigo-100 hover:text-white md:visible`};
`;

export const Text = styled.h1`
  ${tw`text-lg`};
`;

export const Image = styled.figure`
  ${tw`w-10 h-10 mr-3 rounded `};

  img {
    ${tw`border-4 border-white rounded-full`};
  }
`;
